import React from "react"


import { 
     EuiButton,
     EuiFieldText,
     EuiForm,
     EuiFormRow,
     EuiDescribedFormGroup,
     EuiPage,
     EuiTitle,
     EuiPageContent,
     EuiSpacer,
     EuiTextArea
    
    } from '@elastic/eui';

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {

    
  return <Layout>
    <SEO 
      title="Контакты"
      canonical="/contacts/"
    />
    
    <EuiPage restrictWidth={ 1000 }>
      <EuiPageContent>    
      <EuiTitle size="m">
            <h1>Форма обратной связи:</h1>
          </EuiTitle>
       <EuiSpacer />   
    <EuiForm>
      <form data-netlify="true">
      <EuiDescribedFormGroup
        title={<h3>Ваше имя</h3>}
        description={`Как мы можем к Вам обращаться в ответном письме?`}
       >
        <EuiFormRow label="Имя">
          <EuiFieldText name="first" aria-label="Example" />
        </EuiFormRow>
      </EuiDescribedFormGroup>

      <EuiDescribedFormGroup 
        title={<h3>Ваш email</h3>}
        description={`Очень важно, чтобы Вы ввели корректный email-адрес, иначе мы не сможем Вам ответить :-)`}
      >
        <EuiFormRow label="Email">
          <EuiFieldText name="first" />
        </EuiFormRow>
      </EuiDescribedFormGroup>

      <EuiDescribedFormGroup 
        title={<h3>Ваше сообщение</h3>}
        description={`Что Вы хотите предложить или спросить у администрации сайта?`}
      >
       <EuiTextArea
        placeholder="Введите сообщение"
        aria-label="Use aria labels when no actual label is in use"
      />
         </EuiDescribedFormGroup>

         <EuiSpacer />
        <div className={`eui-textCenter`}>
            <EuiButton type="submit" fill>
                Отправить
            </EuiButton>
        </div>
        </form>
    </EuiForm>
    </EuiPageContent>
    </EuiPage>
  </Layout>
}





